import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/mainLayout/MainLayout.jsx";
import "../templates/contacts/contact.scss";
import { ContactForm } from "../components/contactForm/ContactForm.jsx";
import { Prices } from "../components/prices/Prices.jsx";

const PricePage = ({ data }) => {
  return (
    <Layout
      withBackground
      title="Siuntų ir krovinių pervežimo kainos, Lietuva - Anglija"
      description="Visos siuntos yra apdraudžiamos, todėl jų saugumas - garantuotas.
    Būkite užtikrinti, kad siuntos gavėjus pasieks laiku."
    >
      <div className="prices-page">
        <Prices inverted />
      </div>
    </Layout>
  );
};

export default PricePage;

export const pageQuery = graphql`
  query PricePage {
    markdownRemark(frontmatter: { templateKey: { eq: "contacts" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
      }
    }
  }
`;
