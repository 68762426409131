import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

import IconTrolly from "../../svg/trolly.svg";
import IconTruck from "../../svg/truck.svg";
import IconBox from "../../svg/box.svg";
import IconArrow from "../../svg/arrow-right.svg";
import "./prices.scss";

export const Prices = ({ inverted }) => {
  return (
    <div
      className={classNames("pricing", {
        inverted: inverted,
      })}
      id="prices"
    >
      <div className="pricing__frame">
        {inverted ? (
          <h1 className="pricing__title">
            Siuntų ir krovinių pervežimo kainos
          </h1>
        ) : (
          <h2 className="pricing__title">
            Siuntų ir krovinių pervežimo kainos
          </h2>
        )}

        <div className="pricing__row">
          <div className="pricing__column">
            <div className="single-price__content">
              <div className="single-price__icon">
                <IconBox />
              </div>
              <h3 className="single-price__title">Siuntų pervežimas</h3>
              <p className="single-price__desc">
                Siuntų pristatymas vyksta reguliariai, todėl galite būti tikri,
                kad jūsų siunta bus pristatyta laiku.
              </p>
            </div>
            <ul className="single-price__list">
              <li className="single-price__item">
                <span className="single-price__label">Siunta iki 5kg</span>
                <span className="single-price__price">€30.00 / £30.00</span>
              </li>
              <li className="single-price__item">
                <span className="single-price__label">Siunta iki 30kg</span>
                <span className="single-price__price">€40.00 / £40.00</span>
              </li>
            </ul>
          </div>
          <div className="pricing__column">
            <div className="single-price__content">
              <div className="single-price__icon">
                <IconTrolly />
              </div>
              <h3 className="single-price__title">Krovinių pervežimas</h3>
              <p className="single-price__desc">
                Nuo naujų ar senų baldų, buitinės technikos iki automobilių
                detalių ir kitų įrenginių.
              </p>
            </div>
            <ul className="single-price__list">
              <li className="single-price__item">
                <span className="single-price__label">
                  Skalbimo mašina, džiovyklė
                </span>
                <span className="single-price__price">nuo €50.00 / £50.00</span>
              </li>
              <li className="single-price__item">
                <span className="single-price__label">Dviratis</span>
                <span className="single-price__price">nuo €45.00 / £45.00</span>
              </li>
            </ul>
          </div>
          <div className="pricing__column">
            <div className="single-price__content">
              <div className="single-price__icon">
                <IconTruck />
              </div>
              <h3 className="single-price__title">Tarptautinis kraustymas</h3>
              <p className="single-price__desc">
                Mūsų komanda užtikrins, kad perkraustymo procesas būtų atliktas
                profesionaliai ir laiku.
              </p>
            </div>
            <ul className="single-price__list">
              <li className="single-price__item">
                <span className="single-price__label">
                  Autobusiukas kraustymui{" "}
                </span>
                <span className="single-price__price">
                  nuo €900.00 / £900.00
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="pricing__footer">
          <h3 className="pricing__footer-title">
            Visos siuntos yra apdraudžiamos, todėl jų saugumas - garantuotas.
          </h3>
          <p className="pricing__footer-desc">
            Būkite užtikrinti, kad siuntos gavėjus pasieks laiku.
          </p>
          <Link className="pricing__btn-cta" to="/registracija-siuntos/">
            Registruoti siuntą
            <IconArrow />
          </Link>
        </div>
      </div>
    </div>
  );
};
